import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { StaticImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faTooth, faPhone } from '@fortawesome/free-solid-svg-icons'

const Dentist = () => {
  return (
    <>
      <Layout>
        <SEO
          title="DDSLocator | Dental Patient Marketing and Advertising For DSO and Group Practices"
          description="DDSLocator delivers patient leads to DSO and group dental practices using a unique mix of dental advertising and marketing strategies. Acquire more patients and increase revenue for your DSO or group dental practice."
        />
        <Container fluid={true}>
          <Row>
            <Col md="12">
              <div className="main-image mt-4">
                <StaticImage
                  src="../images/DDSLocator-header-PATIENTS-2500x651.jpg"
                  alt="Patients"
                  placeholder="none"
                  layout="fullWidth"
                />
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid={true} className="mb-5">
          <Row>
            <Col md="12">
              <h2 className="mb-3 mt-5 text-center title">
                Get More Patients for Your Practices with DDSLocator
              </h2>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <h2 className="mb-3 mt-5 text-center">
                1. Subscribe To DDSLocator
              </h2>
              <p className="text-center">Bulk subscribe member practices</p>
              <div className="text-center">
                <FontAwesomeIcon icon={faSearch} size="5x" />
              </div>
            </Col>
            <Col md="4">
              <h2 className="mb-3 mt-5 text-center">
                2. Complete Your Profile
              </h2>
              <p className="text-center">Centralized content control</p>
              <div className="text-center">
                <FontAwesomeIcon icon={faTooth} size="5x" />
              </div>
            </Col>
            <Col md="4">
              <h2 className="mb-3 mt-5 text-center">
                3. Receive Patient Leads
              </h2>
              <p className="text-center">Group admin and practice dashboards</p>
              <div className="text-center">
                <FontAwesomeIcon icon={faPhone} size="5x" />
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid={true} className="mt-4">
          <Row>
            <Col lg="12" className="">
              <div
                className="p-4"
                style={{ backgroundColor: `#7aa6ad`, color: `#fff` }}
              >
                <div className="text-left">
                  <h2>DDSLocator delivers patient leads!</h2>
                  <ul>
                    <li>
                      Over 200,000 potential patients visit a growing network of
                      consumer oral health care websites that feature the
                      DDSLocator search engine
                    </li>
                    <li>
                      The growth of the DDSLocator patient-base is supported by
                      national social and email marketing campaigns featuring
                      multiple consumer oral health care brands
                    </li>
                    <li>
                      On average, a practice can expect 3-5 qualified patient
                      leads each month
                    </li>
                    <li>
                      Converting just one patient lead per month to a
                      restorative case can lead to tens of thousands of
                      increased revenue per year
                    </li>
                    <li>
                      DDSLocator is actively growing its patient-base which will
                      continue to expand the reach of your practice at no
                      additional cost
                    </li>
                  </ul>
                </div>
                <div className="text-left">
                  <h2>DDSLocator features:</h2>
                  <ul>
                    <li>
                      Centralized and / or localized profile control of member
                      practice profiles
                    </li>
                    <li>
                      Customizable, searchable, practice profile listing
                      location, contact information, directions, specialty,
                      services, hours, description, insurance and financing
                      plans, practice images and more for each member practice.
                    </li>
                    <li>
                      A patient lead generation form with built in email
                      notifications to the DSO or Group admin, individual
                      practice and patient
                    </li>
                    <li>
                      Centralized and / or localized reporting and analytics
                      dashboards for tracking leads and visitors to member
                      practice profiles
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container fluid={true} className="mt-4">
          <Row>
            <Col
              lg="12"
              className="flex justify-content-center align-content-center"
            >
              <div className="text-center purple-box">
                <div>
                  <p>
                    Many patients report a personal referral as the number one
                    factor that influences the choice of a new dentist. The
                    second spot is commonly reserved for online reviews, and
                    location is a close third. Whether referred or not, most
                    patients go online to do some quick research before
                    ultimately making a decision to contact a dentist. For many
                    individual practices, achieving a high search ranking for
                    their own website presents a challenge, so it makes sense to
                    leverage as many dental practice location services as
                    possible. When a patient looks for a dentist in a city of
                    one of your member practices, you want them to find the
                    contact information of that member practice regardless of
                    what service they find it on. DDSLocator is one such
                    service. We are not the biggest locator service, however, we
                    are actively growing. We hope your DSO or Group practice
                    will grow with us!
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="mb-5">
          <Row>
            <Col md="12">
              <h1 className="mt-5 mb-3 text-center">
                Are you a dental professional?
              </h1>
              <p>
                Find out how DenMat can help drive more patients to your
                practice by joining our DDSLocator dental practice directory.
                Your practice will be listed on several patient-facing websites
                that draw thousands of visitors each month including
                Lumineers.com, SnapOnSmile.com, OralProCare.com and
                DDSLocator.com. You will be notified immediately when a lead has
                been generated for your practice so you can make contact and
                schedule an appointment. Just one new patient will likely pay
                for the service for the entire year!{' '}
              </p>
              <div className="button-container text-center">
                <a
                  href="https://www.denmat.com/memberships/ddslocator"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="sub-button"
                >
                  Learn More
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  )
}

export default Dentist
